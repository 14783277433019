let rootCmp = () => import(/* webpackChunkName: "courses-main" */ './index');
let homeCmp = () => import(/* webpackChunkName: "courses-main" */ './home/index');
import cfgRoutes from './config/routes';
import cmsRoutes from './cms/routes';
import lessonsRoutes from './lessons/routes';
import statsRoutes from './stats/routes';
import webinarRoutes from './webinars/routes';

export default [
    {
        name: "course",
        path: "course/:course",
        component: rootCmp,
        redirect: "course/:course/home",
        children: [
            {
                path: "home",
                component: homeCmp,
                meta: {bcTitle: "Menu"}
            },
            ...cfgRoutes,
            ...cmsRoutes,
            ...lessonsRoutes,
            ...webinarRoutes,
            ...statsRoutes
        ]
    },


]