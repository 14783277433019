let rootCmp = () => import(/* webpackChunkName: "courses-main" */ './index');
let listCmp = () => import(/* webpackChunkName: "courses-main" */ './listing/index');
let createCmp = () => import(/* webpackChunkName: "courses-main" */ './create/index');
import courseRoutes from "./details/routes";

export default [
    {
        path: "courses",
        redirect: 'courses/list',
        component: rootCmp,
        children: [
            {
                path: "list",
                component: listCmp,
                meta: {bcTitle: "Course list"},
            },
            {
                path: "create",
                component: createCmp,
                meta: {bcTitle: "Create a new course"},
            }
        ]
    },
    ...courseRoutes
]