let listingRootCmp = () => import(/* webpackChunkName: "webinars" */ './index');
let listingListCmp = () => import(/* webpackChunkName: "webinars" */ './listing/index');
let listingCreateCmp = () => import(/* webpackChunkName: "webinars" */ './create/index');
let detailsRootCmp = () => import(/* webpackChunkName: "webinars" */ './details/index');
let detailsMainDataCmp = () => import(/* webpackChunkName: "webinars" */ './details/main/index');
let detailsChatCmp = () => import(/* webpackChunkName: "webinars" */ './details/chat/index');
let detailsThemeCmp = () => import(/* webpackChunkName: "webinars" */ './details/theme/index');
let detailsReminderCmp = () => import(/* webpackChunkName: "webinars" */ './details/reminder/index');
let detailsRecordingsCmp = () => import(/* webpackChunkName: "webinars" */ './details/recordings/index');
let detailsFormsCmp = () => import(/* webpackChunkName: "webinars" */ './details/forms/index');
let detailsStatsCmp = () => import(/* webpackChunkName: "webinars" */ './details/stats/index');

export default [
    {
        name: "course-webinars",
        path: "webinars",
        redirect: 'webinars/listing',
        component: listingRootCmp,
        children: [
            {
                path: "listing",
                component: listingListCmp,
                meta: {bcTitle: "Webinars"},
            },
            {
                path: "create",
                component: listingCreateCmp,
                meta: {bcTitle: "Create Webinar"},
            }
        ]
    },
    {
        name: "course-webinar",
        path: "webinar/:webinar",
        redirect: 'webinar/:webinar/main',
        component: detailsRootCmp,
        children: [
            {
                path: "main",
                component: detailsMainDataCmp,
                meta: {bcTitle: "Webinar main data"},
            },
            {
                path: "chat",
                component: detailsChatCmp,
                meta: {bcTitle: "Chat"},
            },
            {
                path: "theme",
                component: detailsThemeCmp,
                meta: {bcTitle: "Webinar theme"},
            },
            {
                path: "reminder",
                component: detailsReminderCmp,
                meta: {bcTitle: "Webinar reminder"},
            },
            {
                path: "recordings",
                component: detailsRecordingsCmp,
                meta: {bcTitle: "Recordings"},
            },
            {
                path: "forms",
                component: detailsFormsCmp,
                meta: {bcTitle: "Forms"},
            },
            {
                path: "stats",
                component: detailsStatsCmp,
                meta: {bcTitle: "Webinar statistics"},
            }
        ]
    }
]