let usage = () => import(/* webpackChunkName: "admin" */ './usage/index');

import customer_routes from "./customers/routes";

export default [
    {
        path: "usage",
        component: usage
    },
    ...customer_routes
]