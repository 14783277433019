import DateTime from "luxon/src/datetime";
import {Duration} from "luxon";

let toasterCallback = null;

export default {

    toastOptions: {
        variant: "info",
        toaster: "b-toaster-top-center",
        noCloseButton: true,
        solid: true
    },
    toastOptionsWarn: {
        variant: "danger",
        toaster: "b-toaster-top-center",
        noCloseButton: true,
        solid: true
    },
    registerToaster(callback) {
        toasterCallback = callback;
    },
    showToast(message) {
        if (toasterCallback != null) {
            toasterCallback(message, this.toastOptions);
        }
    },
    showToastWarn(message) {
        if (toasterCallback != null) {
            toasterCallback(message, this.toastOptionsWarn);
        }
    },
    uniqueId() {
        return new Date().getTime();
    },
    limitFilter(value, maxLength) {

        if (!value) return "";

        if (value.length > maxLength) {
            return value.substring(0, maxLength) + "...";
        } else {
            return value;
        }
    },
    fileSizeFilter(size) {
        var i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    },
    isoDateFormatFilter(isoDate, format) {
        if (!isoDate) return "";

        return DateTime.fromISO(isoDate).toFormat(format);
    },
    secToHmsFilter(seconds) {

        if (!seconds) return "";

        let result = Duration.fromObject({seconds: seconds});

        return result.toFormat("h:mm:ss");

    },
    formatCurrencyFilter(number) {
        return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(number)
    },
    formatThousand(number) {
        return new Intl.NumberFormat('it-IT').format(number)
    },
    yesNoFilter(value) {
        return value ? "Yes" : "No";
    }
}