<template>

    <b-nav-item-dropdown :text="installation.info.entity.Name|limit(20)" v-if="!states.loading">
      <b-dropdown-item :to="`/app/in/inst/${installationId}`">Summary</b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item :to="`/app/in/inst/${installationId}/courses`">Courses</b-dropdown-item>
        <b-dropdown-item v-if="isPower" :to="`/app/in/inst/${installationId}/students`">Students</b-dropdown-item>
        <b-dropdown-item v-if="isResponsible" :to="`/app/in/inst/${installationId}/design`">Customize</b-dropdown-item>
        <b-dropdown-item v-if="isResponsible" :to="`/app/in/inst/${installationId}/operators`">Collaborators</b-dropdown-item>
        <b-dropdown-item v-if="isResponsible" :to="`/app/in/inst/${installationId}/usage`">Usage</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="openSite">Visit site <b-icon-box-arrow-up-right class="ml-2" size="sm"></b-icon-box-arrow-up-right></b-dropdown-item>
    </b-nav-item-dropdown>

</template>

<script>
    export default {
        props: {
            installationId: String
        },
        data() {
            return {
                installation: {},
                op: {role: null},
                states: {
                    loading: true
                }
            }
        },
        computed: {
            isResponsible() {
                return this.op.role === "Responsible"
            },
            isPower() {
                return this.op.role === "Responsible" || this.op.role === "PowerOperator"
            }
        },
        created() {

            let calls = [
                this.APL.apiclient.post("/manage/inst/get/" + this.installationId, null),
                this.APL.user.getRole(this.installationId)
            ];

            Promise.all(calls).then(resp => {
                this.installation = resp[0];
                this.op = resp[1];
                this.states.loading = false;
            })

        },
        methods:{
            openSite(){
                window.open(this.installation.info.baseUrl);
            }
        }

    }
</script>

