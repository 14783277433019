<template>
  <b-navbar type="dark" variant="info" class="app-topbar">

    <b-navbar-brand to="/">
      <img class="app-logo" src="/img/logo.svg"/>
    </b-navbar-brand>

    <b-navbar-nav v-if="user.entity.Is_Admin">
      <b-nav-item-dropdown text="Admin">
        <b-dropdown-item to="/app/in/customers">Customers</b-dropdown-item>
        <b-dropdown-item to="/app/in/usage">Usage report</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-navbar-nav v-if="installationId">
      <installation :installation-id="installationId"></installation>
    </b-navbar-nav>

    <b-navbar-nav v-if="courseId">
      <course :installation-id="installationId" :course-id="courseId"></course>
    </b-navbar-nav>

    <b-navbar-nav class="ml-auto">

      <b-nav-item-dropdown right>
        <template v-slot:button-content>
          <em>{{ user.entity.First_Name }}</em>
        </template>
        <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

  </b-navbar>
</template>

<script>
import installation from "./partials/installation";
import course from "./partials/course";

export default {
  components: {
    installation,
    course
  },
  computed: {
    user() {
      return this.APL.user.current;
    },
    installationId() {
      return this.$route.params.installation
    },
    courseId() {
      return this.$route.params.course
    }
  },
  methods: {
    logout() {
      this.APL.user.logout().then(() => {
        this.$router.push({name: "Login"});
      });
    }
  }
}
</script>

