export default {

    objToString(obj) {
        if (typeof (obj) != 'undefined' && obj != null) {
            return JSON.stringify(obj);
        } else {
            return null;
        }
    },

    stringToObj(str) {
        if (!str || str === "") return {};

        return JSON.parse(str);
    },

    isNotEmpty(obj) {
        return (typeof (obj) != 'undefined' && obj != null && obj !== "");
    },
    isEmpty(obj) {
        return !this.isNotEmpty(obj);
    }
}