let rootCmp = () => import(/* webpackChunkName: "inst-design" */ './index');
let themeCmp = () => import(/* webpackChunkName: "inst-design" */ './theme/index');
let textsCmp = () => import(/* webpackChunkName: "inst-design" */ './texts/index');
let footerCmp = () => import(/* webpackChunkName: "inst-design" */ './footer/index');
let i18nCmp = () => import(/* webpackChunkName: "inst-design" */ './i18n/index');
let mailCmp = () => import(/* webpackChunkName: "inst-design" */ './mail/index');
let pagesCmp = () => import(/* webpackChunkName: "inst-design" */ './pages/index');
let studentFieldsCmp = () => import(/* webpackChunkName: "inst-design" */ './student-fields/index');
let registrationCmp = () => import(/* webpackChunkName: "inst-design" */ './registration/index');

export default [
    {
        path: "design",
        redirect: 'design/theme',
        component: rootCmp,
        children: [
            {
                path: "theme",
                component: themeCmp,
                meta: {bcTitle: "Customize theme"},
            },
            {
                path: "texts",
                component: textsCmp,
                meta: {bcTitle: "Customize texts"},
            },
            {
                path: "pages",
                component: pagesCmp,
                meta: {bcTitle: "Create pages"},
            },
            {
                path: "footer",
                component: footerCmp,
                meta: {bcTitle: "Customize footer"},
            },
            {
                path: "i18n",
                component: i18nCmp,
                meta: {bcTitle: "Customize languages"},
            },
            {
                path: "mail",
                component: mailCmp,
                meta: {bcTitle: "E-mail options"},
            },
            {
                path: "students-fields",
                component: studentFieldsCmp,
                meta: {bcTitle: "Student's field"},
            },
            {
                path: "registration",
                component: registrationCmp,
                meta: {bcTitle: "Registration"},
            }

        ]
    }
]