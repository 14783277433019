let rootCmp = () => import(/* webpackChunkName: "courses-stats" */ './index');
let studentsCmp = () => import(/* webpackChunkName: "courses-stats" */ './students/listing');

export default [
    {
        name: "course-stats",
        path: "stats",
        redirect: 'stats/students',
        component: rootCmp,
        children: [
            {
                path: "students",
                component: studentsCmp,
                meta: {bcTitle: "Statistics"},
            }
        ]
    }
]