import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import user from "./services/accounting/user";
import ui from "./services/ui/ui";
import i18n from "./services/ui/i18n";
import colors from "./services/ui/colors";
import json from "./services/language/json";
import apiclient from "./services/networking/apiclient";
import courseEnum from "./services/course/enums";
import "../styles/styles.scss";

Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)

Vue.use(VueCookies)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(CKEditor);

Vue.config.productionTip = false;

// APL
Vue.prototype.APL = {
    user,
    ui,
    colors,
    apiclient,
    i18n,
    json,
    courseEnum
};

// filters
Vue.filter("limit", ui.limitFilter);
Vue.filter("fileSize", ui.fileSizeFilter);
Vue.filter("trans", i18n.translateFilter);
Vue.filter("dateFormat", ui.isoDateFormatFilter);
Vue.filter("secToHms", ui.secToHmsFilter);
Vue.filter("currency", ui.formatCurrencyFilter);
Vue.filter("thousand", ui.formatThousand);
Vue.filter("yesNo", ui.yesNoFilter);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
