import Vue from 'vue'
import VueRouter from 'vue-router'
import In from "./views/in/in"
import Out from "./views/out/out"
import userSvc from "./services/accounting/user";

import admin_routes from "./views/in/admin/routes";
import installation_routes from "./views/in/installation/routes";


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/app/in/dashboard'
    },
    {
        path: '/invite/:token',
        redirect: '/app/out/invite/:token'
    },

    {
        path: '/app/in',
        component: In,
        children: [
            {
                path: 'dashboard',
                name: 'Home',
                component: () => import('./views/in/home/index')
            },
            ...admin_routes,
            ...installation_routes
        ]
    },
    {
        path: '/app/out',
        component: Out,
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('./views/out/login/login')
            },
            {
                path: 'invite/:token',
                component: () => import('./views/out/invite/index')
            },
            {
                name: 'recovery',
                path: 'recovery',
                component: () => import('./views/out/recovery/start')
            },
            {
                path: 'recovery/:token',
                component: () => import('./views/out/recovery/apply')
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {

    return new Promise(resolve => {

        // define if we're requesting a private area link
        let private_area = to.fullPath.indexOf("/out") === -1;

        userSvc.isLoggedIn().then(resp => {

            // check if user is logged
            if (resp.logged) {

                // ensure user data are loaded
                userSvc.loadCurrent().then(() => {
                    if (private_area) {
                        next();
                    } else {
                        // avoid public link when logged
                        next({name: "Home"});
                    }
                });

            } else {
                if (private_area) {
                    next({name: "Login"});
                } else {
                    next();
                }
            }
            resolve();
        });
    });
});

export default router;
