let rootCmp = () => import(/* webpackChunkName: "courses-cfg" */ './index');
let mainCmp = () => import(/* webpackChunkName: "courses-cfg" */ './main/index');
let themeCmp = () => import(/* webpackChunkName: "courses-cfg" */ './theme/index');
let certCmp = () => import(/* webpackChunkName: "courses-cfg" */ './cert/index');
let authCmp = () => import(/* webpackChunkName: "courses-cfg" */ './auth/index');
let studentsCmp = () => import(/* webpackChunkName: "courses-cfg" */ './auth/students/index');
let webinarCmp = () => import(/* webpackChunkName: "courses-cfg" */ './webinars/index.vue');

export default [
    {
        name: "course-cfg",
        path: "cfg",
        redirect: 'cfg/main',
        component: rootCmp,
        children: [
            {
                path: "main",
                component: mainCmp,
                meta: {bcTitle: "Main data"},
            },
            {
                path: "theme",
                component: themeCmp,
                meta: {bcTitle: "Graphic theme"},
            },
            {
                path: "cert",
                component: certCmp,
                meta: {bcTitle: "Certificate"},
            },
            {
                path: "auth",
                redirect: 'auth/students',
                component: authCmp,
                children: [
                    {
                        path: "students",
                        component: studentsCmp,
                        meta: {bcTitle: "Enrollment"},
                    }
                ]

            },

            {
                path: "webinars",
                component: webinarCmp,
                meta: {bcTitle: "Webinars options"},
            },

        ]
    }
]