<template>
    <div>

        <topbar></topbar>

        <router-view></router-view>

    </div>
</template>

<script>
    import topbar from "../../commons/template/topbar/topbar";

    export default {
        components: {
            topbar
        },
        created() {
            let $this = this;

            this.APL.ui.registerToaster((msg,opt) => {

                $this.$bvToast.toast(msg, opt);
            });
        }
    }
</script>

