let rootCmp = () => import(/* webpackChunkName: "inst" */ './index');
let homeCmp = () => import(/* webpackChunkName: "inst" */ './home/index');
let operatorsCmp = () => import(/* webpackChunkName: "inst" */ './operators/index');
let usageCmp = () => import(/* webpackChunkName: "inst" */ './usage/index');
import designRoutes from "./design/routes"
import studentsRoutes from "./students/routes"
import coursesRoutes from "./courses/routes"

export default [
    {
        name: "installation",
        path: "inst/:installation",
        redirect: 'inst/:installation/home',
        component: rootCmp,
        children: [
            {
                path: "home",
                component: homeCmp,
                meta: {bcTitle: ""}
            },
            {
                path: "operators",
                component: operatorsCmp,
                meta: {bcTitle: "Collaborators"}
            },
            {
                path: "usage",
                component: usageCmp,
                meta: {bcTitle: "Billing report"}
            },
            ...designRoutes,
            ...studentsRoutes,
            ...coursesRoutes
        ]
    }
]