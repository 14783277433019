export default {

    modules: [
        {value: "Text", text: "Slides", shortName: "slides"},
        {value: "Video", text: "Video lesson", shortName: "video"},
        {value: "Audio", text: "Audio lesson", shortName: "audio"},
        {value: "Scorm", text: "Scorm package", shortName: "scorm"},
        {value: "Quiz", text: "Interactive Quiz", shortName: "quiz"},
        {value: "Form", text: "Form", shortName: "form"},

    ],
    module_name(value) {
        var item = this.modules.find(i => i.value === value);

        if (item) {
            return item.text;
        } else {
            return "";
        }
    },
    module_shortname(value) {
        var item = this.modules.find(i => i.value === value);

        if (item) {
            return item.shortName;
        } else {
            return "";
        }
    }

}