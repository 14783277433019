const langs = [
        {
            code: "it",
            code3: "ita",
            name: "Italian"
        },
        {
            code: "en",
            code3: "eng",
            name: "English"
        }
    ],
    translate = function (value) {

        if (!value) return "";


        let langs = ["it", "en"];

        for (let i = 0; i < langs.length; i++) {
            if (value[langs[i]] && value[langs[i]] !== "") {
                return value[langs[i]];
            }
        }

        return "";
    },
    hasI18nAny = function (value) {
        return translate(value) !== "";
    },
    hasI18nAll = function (value) {
        let result = true;

        langs.forEach(l => {

            if (!value[l.code] || value[l.code] === "") {
                result = false;
            }

        });

        return result;
    };


export default {

    langs,

    translateFilter: translate

}

export {hasI18nAny, hasI18nAll};