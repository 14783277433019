let root = () => import(/* webpackChunkName: "admin" */ './index');
let listing = () => import(/* webpackChunkName: "admin" */ './listing/index');
let details = () => import(/* webpackChunkName: "admin" */ './details/index');

export default [
    {
        path: "customers",
        redirect: 'customers/listing',
        component: root,
        children: [
            {
                path: "listing",
                component: listing,
                meta: {bcTitle: "Customer list"}
            },
            {
                path: "details/new",
                props: {id: "new"},
                component: details,
                meta: {bcTitle: "Add customer"}
            },
            {
                path: "details/:id",
                props: true,
                component: details,
                meta: {bcTitle: "Customer details"}
            }]
    }
]