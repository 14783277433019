import axios from "axios";

var base_url = process.env.VUE_APP_API_BASEURL;

export default {

    post(url, data) {

        let full_url = url;

        return new Promise(function (resolve) {

            let headers = {
                'Accept': "application/json",
                'Content-Type': "application/json"
            };

            let client = axios.create({
                baseURL: base_url,
                headers: headers
            });

            client
                .post(full_url, data)
                .then(response => {

                    if (response.status !== 200) {
                        console.error("Error calling " + url);
                        console.error(response);
                        resolve(null);
                    } else {
                        resolve(response.data);
                    }

                })
                .catch(err => {
                    console.error("System error calling " + url);
                    console.error(err.message);
                    resolve(null);
                });
        });

    },
    downloadFile(url, data, name) {
        let full_url = url;

        return new Promise(function (resolve) {

            let headers = {
                'Content-Type': "application/json"
            };


            let client = axios.create({
                baseURL: base_url,
                headers: headers
            });

            client
                .post(full_url,
                    data, {
                        responseType: 'blob'
                    })
                .then(response => {

                    if (response.status !== 200) {
                        console.error("Error calling " + url);
                        console.error(response);
                        resolve();
                    } else {

                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', name);
                        window.document.body.appendChild(fileLink);

                        fileLink.click();

                        resolve();
                    }

                })
                .catch(err => {
                    console.error("System error calling " + url);
                    console.error(err.message);
                    resolve();
                });
        });
    }
};
