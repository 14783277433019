let rootCmp = () => import(/* webpackChunkName: "courses-lessons" */ './index');
let structureCmp = () => import(/* webpackChunkName: "courses-lessons" */ './structure/index');

export default [
    {
        name: "course-lessons",
        path: "lessons",
        redirect: 'lessons/structure',
        component: rootCmp,
        children: [
            {
                path: "structure",
                component: structureCmp,
                meta: {bcTitle: "Lessons structure"},
            }
        ]
    }
]