let rootCmp = () => import(/* webpackChunkName: "students" */ './index');
let listCmp = () => import(/* webpackChunkName: "students" */ './listing/index');
let groupsCmp = () => import(/* webpackChunkName: "students" */ './groups/index');


export default [
    {
        path: "students",
        redirect: 'students/list',
        component: rootCmp,
        children: [
            {
                path: "list",
                component: listCmp,
                meta: {bcTitle: "Student list"},
            },
            {
                path: "groups",
                component: groupsCmp,
                meta: {bcTitle: "Student groups"},
            }
        ]
    }
]