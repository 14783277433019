let rootCmp = () => import(/* webpackChunkName: "courses-cms" */ './index');
let teachersCmp = () => import(/* webpackChunkName: "courses-cms" */ './teachers/index');
let libraryCmp = () => import(/* webpackChunkName: "courses-cms" */ './library/category/index');
let faqCmp = () => import(/* webpackChunkName: "courses-cms" */ './faq/category/index');

export default [
    {
        name: "course-cms",
        path: "cms",
        redirect: 'cms/teachers',
        component: rootCmp,
        children: [
            {
                path: "teachers",
                component: teachersCmp,
                meta: {bcTitle: "Teachers"},
            },
            {
                path: "library",
                component: libraryCmp,
                meta: {bcTitle: "Library"},
            },
            {
                path: "faq",
                component: faqCmp,
                meta: {bcTitle: "FAQ"},
            }
        ]
    }
]