<template>

  <b-nav-item-dropdown :text="course.entity.Name|limit(20)" v-if="!states.loading">
    <b-dropdown-item :to="`/app/in/inst/${installationId}/course/${courseId}`">Summary</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item :to="`/app/in/inst/${installationId}/course/${courseId}/cfg`">Options</b-dropdown-item>
    <b-dropdown-item :to="`/app/in/inst/${installationId}/course/${courseId}/lessons`">Lessons</b-dropdown-item>
    <b-dropdown-item :to="`/app/in/inst/${installationId}/course/${courseId}/webinars`">Webinars</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item :to="`/app/in/inst/${installationId}/course/${courseId}/cms/teachers`">Teachers</b-dropdown-item>
    <b-dropdown-item :to="`/app/in/inst/${installationId}/course/${courseId}/cms/library`">Library</b-dropdown-item>
    <b-dropdown-item :to="`/app/in/inst/${installationId}/course/${courseId}/cms/faq`">FAQ</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item :to="`/app/in/inst/${installationId}/course/${courseId}/stats/students`">Statistics</b-dropdown-item>

  </b-nav-item-dropdown>

</template>

<script>
export default {
  props: {
    installationId: String,
    courseId: String
  },
  data() {
    return {
      course: {},
      states: {
        loading: true
      }
    }
  },

  created() {

    this.APL.apiclient.post(`/manage/courses/structure/get/${this.installationId}/${this.courseId}`).then(resp => {
      this.course = resp.info;
      this.states.loading = false;
    })

    // this.APL.user.getRole(this.installationId).then(resp => {
    //
    //     let enabled = ["PowerOperator", "Responsible"];
    //
    //     this.states.can_see_authorization = enabled.some(i => i === resp.role);
    // })

  }

}
</script>

