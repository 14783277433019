import apiclient from "../networking/apiclient";


const NULL_USER = {
    entity: {
        First_Name: "",
        Is_Admin: false
    }
};

export default {

    isLoggedIn() {
        return new Promise(resolve => {

            apiclient.post("accounting/checklogin").then(resp => {
                resolve(resp);
            });

        });
    },
    login(email, password) {

        return new Promise(resolve => {

            let dto = {
                Email: email,
                Password: password
            };

            apiclient.post("accounting/login", dto).then(resp => {
                resolve(resp);
            })
        })
    },
    logout() {
        return new Promise(resolve => {

            apiclient.post("accounting/logout").then(() => {
                this.current = NULL_USER;
                resolve();
            })
        })
    },
    current: NULL_USER,
    loadCurrent() {
        return new Promise(resolve => {

            if (this.current !== NULL_USER) {
                resolve(this.current);
            } else {
                apiclient.post("accounting/operator/current").then((resp) => {
                    this.current = resp;
                    resolve(resp);
                })
            }
        })
    },
    getRole(installationId) {
        return new Promise(resolve => {

            apiclient.post("/accounting/operator/current/role/" + installationId).then((resp) => {
                resolve(resp);
            })
        })
    }
}